<template>
   <div
      id="app-sidebar-2"
      class="cph-dark-blue h-full d-none lg:block flex-shrink-0 absolute lg:fixed left-0 top-0 z-1 select-none cph-surface-ground border-right-1 lg:border-right-none "
      style="width:220px"
   >
      <div class="flex flex-column h-full" >
         <div
            class="flex align-items-center mt-3 ml-5 mb-2 flex-shrink-0 fonda-logo"
         >
            <!-- <nuxt-img src="/images/idoedge/IDO Edge_LogoRectangular_ColourWhite-cropped.svg" class="block" alt="Image" width="166"   /> -->
            <nuxt-img src="/images/idoedge/IDO Edge_LogoRectangular_WhiteTransparent-cropped.svg" class="block" alt="Image" width="166"   />
         </div>
         <div class="overflow-y-auto inner-menu" >
            <ul class="list-none p-3 m-0">
               <li v-if="customerId">
                  <div
                     class="p-3 flex align-items-center justify-content-between cph-semibold"
                  >
                     <span class="font-bold cph-semibold color-white">PROJECTS</span>
                  </div>
                  <ul class="list-none p-0 m-0 overflow-hidden color-white">
                     <li
                        v-for="project of projectsMenuItems"
                        v-bind:key="project.id"
                     >
                        <SideBarElement
                           :name="anonymizeText(project.name).value"
                           icon="pi pi-folder"
                           :children="project.children"
                           @click="projectClicked(project)"
                        />
                     </li>
                  </ul>
               </li>
               <li v-if="userProfile?.isAdmin">
                  <div
                     class="p-3 flex align-items-center justify-content-between cph-semibold"
                  >
                     <span class="font-bold cph-semibold color-white">ADMIN</span>
                  </div>
                  <ul class="list-none p-0 m-0 overflow-hidden color-white">
                     <li v-if="projectId">
                        <SideBarElement name="Audits"
                           icon="pi pi-refresh"
                           @click="navigateToAudits" />
                     </li>
                     <li>
                        <SideBarElement
                           name="Admin Panel"
                           icon="pi pi-cog"
                           @click="navigateToAdmin"
                        />
                     </li>
                  </ul>
               </li>
            </ul>
         </div>
         <!-- <div
            class="flex align-items-center px-5 flex-shrink-0 cph-logo"         >
            <nuxt-img src="/images/cphmist-logo-white.png" class="block" alt="Image" height="70" />
         </div> -->
      </div>
   </div>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue'
import SideBarElement from '@/components/nav/SideBarElement.vue'

import { useContextProjects } from '@/composables/useContextProjects'
import { useParams } from '@/composables/utils/useParams'
import { useRouter } from 'vue-router'
import { useQueryClient } from '@tanstack/vue-query'
import { useContextUpdate } from '~/composables/mutations/useContextUpdate'
import { useUserProfileQuery } from '~/composables/queries/useUserProfileQuery'
import { useProjectFirewallPoliciesQuery } from '~/composables/queries/useProjectFirewallPoliciesQuery'
import { useUserProjectDisplaySettingsQuery } from '~/composables/queries/useUserProjectDisplaySettingsQuery'
import { useFeatureFlagsQuery } from '~/composables/queries/useFeatureFlagsQuery'

export default defineComponent({
   name: 'SideBar',
   components: {
      SideBarElement
   },
   
   setup() {
      const contextData = useContextProjects()
      const { customerId, projectId } = useParams()

      const router = useRouter()

      const { data: firewallPolicies } = useProjectFirewallPoliciesQuery(projectId)

      const { data: featureFlags } = useFeatureFlagsQuery()

      const projectsMenuItems = computed(() => {
         if (contextData.value) {
            return _orderBy(contextData.value.map(p => {
               return {
                  id: p.id,
                  customerId: p.customerId,
                  name: p.displayName ?? p.name,
                  children: [        
                     {
                        id: 'business-goals',
                        name: 'Business goals',
                        icon: 'pi pi-compass',
                        requireAdmin: false,
                        to: 
                        { 
                           name: 'business-goals', 
                           params: {
                              customerId: p.customerId,
                              projectId: p.id
                           } 
                        },
                        isHidden: false
                     },
                     {
                        id: 'recommendations',
                        name: 'Recommendations',
                        icon: 'pi pi-directions',
                        requireAdmin: false,
                        to: 
                           { 
                              name: 'recommendations', 
                              params: {
                                 customerId: p.customerId,
                                 projectId: p.id
                              } 
                           }
                        
                     },
                     {
                        id: 'dashboards',
                        name: 'Dashboards',
                        icon: 'pi pi-map',
                        requireAdmin: false,
                        to: 
                           { 
                              name: 'wall-dashboards', 
                              params: {
                                 customerId: p.customerId,
                                 projectId: p.id
                              } 
                           }
                        
                     },
                     {
                        id: 'security',
                        name: 'Security',
                        icon: 'pi pi-shield',
                        requireAdmin: false,
                        isHidden: !firewallPolicies.value || firewallPolicies.value.length == 0,
                        children: [
                           {
                              id: 'firewall',
                              name: 'Firewall',
                              icon: 'pi pi-lock',
                              requireAdmin: false,
                              to: 
                                 { 
                                    name: 'firewall', 
                                    params: {
                                       customerId: p.customerId,
                                       projectId: p.id
                                    } 
                                 },
                                 isHidden: !firewallPolicies.value || firewallPolicies.value.length == 0
                           },
                           {
                              id: 'firewall-tracking-lookup',
                              name: 'Tracking ID',
                              icon: 'pi pi-search',
                              requireAdmin: false,
                              to: 
                                 { 
                                    name: 'firewall-tracking-lookup', 
                                    params: {
                                       customerId: p.customerId,
                                       projectId: p.id
                                    } 
                                 },
                           },
                           {
                              id: 'ddos',
                              name: 'DDoS',
                              icon: 'pi pi-globe',
                              requireAdmin: false,
                              isHidden: !featureFlags?.value?.isDdosMitigationEnabled,
                              to: 
                                 { 
                                    name: 'ddos', 
                                    params: {
                                       customerId: p.customerId,
                                       projectId: p.id
                                    } 
                                 },
                           },
                           {
                              id: 'certificates',
                              name: 'Certificates',
                              icon: 'pi pi-verified',
                              requireAdmin: true,
                              to: 
                              { 
                                 name: 'certificates', 
                                 params: {
                                    customerId: p.customerId,
                                    projectId: p.id
                                 } 
                              }            
                           },
                           {
                              id: 'whitelists',
                              name: 'Whitelists',
                              icon: 'pi pi-book',
                              requireAdmin: false,
                              to: 
                                 { 
                                    name: 'whitelists', 
                                    params: {
                                       customerId: p.customerId,
                                       projectId: p.id
                                    } 
                                 }
                           }
                        ] 
                     },
                     {
                        id: 'deployments',
                        name: 'Deployments',
                        icon: 'pi pi-cloud',
                        requireAdmin: false,
                        to: 
                        { 
                           name: 'deployments', 
                           params: {
                              customerId: p.customerId,
                              projectId: p.id
                           } 
                        },
                        isHidden: !p.hasDeploymentsLinked
                     },
                     {
                        id: 'devices',
                        name: 'devices',
                        icon: 'pi pi-desktop',
                        requireAdmin: false,
                        to: 
                           { 
                              name: 'devices', 
                              params: {
                                 customerId: p.customerId,
                                 projectId: p.id
                              } 
                           }
                     },
                     {
                        id: 'endpoints',
                        name: 'Endpoints',
                        icon: 'pi pi-sign-in',
                        requireAdmin: false,
                        to: 
                           { 
                              name: 'endpoints', 
                              params: {
                                 customerId: p.customerId,
                                 projectId: p.id
                              } 
                           }           
                     },
                     {
                        id: 'usages',
                        name: 'Usage',
                        icon: 'pi pi-chart-bar',
                        requireAdmin: true,
                        to: 
                           { 
                              name: 'usage', 
                              params: {
                                 customerId: p.customerId,
                                 projectId: p.id
                              } 
                           }
                     },
                     {
                        id: 'settings',
                        name: 'Settings',
                        icon: 'pi pi-cog',
                        requireAdmin: true,
                        to: 
                           { 
                              name: 'settings', 
                              params: {
                                 customerId: p.customerId,
                                 projectId: p.id
                              } 
                           }
                     }
                  ]
               }
            }), x => { 
               if (x.name == '<undefined>') return 'zzz'
               return x.name
            })
         }
         return []
      })

      const updateContextMutation = useContextUpdate(useQueryClient())
      const projectClicked = (project: any) => {
         updateContextMutation.mutate({ contextCustomerId: project.customerId, contextProjectId: project.id})
         
         router.push({ 
            name: 'dashboard', 
            params: {
               customerId: project.customerId,
               projectId: project.id
            } 
         })
      }

      const { data: userProfile } = useUserProfileQuery()
      const navigateToAdmin = () => {
         router.push({ 
            name: 'customerId-admin', 
            params: {
               customerId: customerId.value,
            } 
         })
      }

      const navigateToAudits = () => {
         router.push({       
               name: 'audit-instances', 
               params: {
                  customerId: customerId.value,
                  projectId: projectId.value
               } 
         })
      }
      
      const { data: settings } = useUserProjectDisplaySettingsQuery()
      const anonymizeText = (text) => {
            return useAnonymizedText(text, settings)
      }

      return {
         contextData,
         projectsMenuItems,
         customerId,
         projectId,
         projectClicked,
         navigateToAdmin,
         userProfile,
         navigateToAudits,
         settings,
         anonymizeText
      }
   }
})
</script>

<style lang="scss">
.fonda-logo {
  position: relative;

  padding-top: 8px;
  padding-bottom: 8px;
  color: #eee;

  span {
   font-weight: 700;
   font-size: 22px;
   position: relative;
  }

  span:after {
   padding:0;margin:0;display:block;/* probably not really needed? */
   content: "";
   width:60%;
   height:3px;/* slight higher to work around rounding errors(?) on some zoom levels in some browsers. */
   background-color: #eee;
   position: absolute;
   left:0;
   bottom:-3px;
   }
}

.cph-logo {
   // position: fixed;
   bottom: 8px;
   height:80px; 
   margin-top: 12px; 
   margin-bottom: 16px; 
}

// .fonda-logo:before {
//   padding:0;margin:0;display:block;/* probably not really needed? */
//   content: "";
//   width:50%;
//   height:1px;/* slight higher to work around rounding errors(?) on some zoom levels in some browsers. */
//   background-color:black;
//   position: absolute;
//   right:0;
//   top:-1px;
// }
/* width */

::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: white;
  border-radius: 10px;
}

.inner-menu {
   height: 100%;
}
</style>