<template>
   <div
      class="flex align-items-center justify-content-between lg:justify-content-start px-5 relative lg:static"
      style="height:60px"
   >
      <a
         v-ripple
         class="cursor-pointer block lg:hidden text-700 mr-3 mt-1 p-ripple"
         v-styleclass="{
            selector: '#app-sidebar-2',
            enterClass: 'd-none',
            enterActiveClass: 'fadeinleft',
            leaveToClass: 'd-none',
            leaveActiveClass: 'fadeoutleft',
            hideOnOutsideClick: true
         }"
      >
         <i class="pi pi-bars text-4xl"></i>
      </a>
     <!-- <a v-ripple class="cursor-pointer block lg:hidden text-700 p-ripple"
         v-styleclass="{ selector: '@next', enterClass: 'd-none',
         enterActiveClass: 'fadein', leaveToClass: 'd-none', leaveActiveClass:
         'fadeout', hideOnOutsideClick: true }">
         <span>Actions</span>
      </a> -->
      <ul
         class="list-none  d-none p-0 mt-0 mb-0 ml-0 mr-3 lg:mr-0 lg:flex lg:align-items-center select-none lg:flex-row border-round
                border-1 lg:border-none cph-surface-border right-0 top-100 z-1 shadow-2 lg:shadow-none absolute lg:static surface-ground lg:w-full"
      >         
         <li > 
            <a
               v-ripple
               class="flex p-3 lg:px-3 lg:py-2 align-items-center text-600 hover:text-900 hover:surface-200 font-medium border-round cursor-pointer
                        transition-duration-150 transition-colors p-ripple"
               v-styleclass="{ selector: '@next', enterClass: 'hidden', enterActiveClass: 'scalein', leaveToClass: 'hidden', leaveActiveClass: 'fadeout', hideOnOutsideClick: true }"
            >
               <i class="pi pi-user text-base lg:text-2xl mr-2 lg:mr-0"></i>
               <span class="block lg:hidden font-medium">Profile</span>
            </a>
            <ul class="list-none py-0 pl-3 m-0 xl:px-0 xl:py-0 bg-white border-round shadow-0 xl:shadow-2 xl:absolute hidden origin-top w-full xl:w-15rem cursor-pointer profile-dropdown">
               <li>
                  <div class="flex p-3 align-items-center cursor-auto">
                     <i class="pi pi-user mr-2"></i>
                     <span class="font-medium">{{ userProfile?.email }}</span>
                  </div>
               </li>
               <li class="logout">
                  <a v-ripple class="flex p-3 align-items-center transition-colors transition-duration-150 p-ripple"
                     @click="navigateTo('/logout')">
                     <i class="pi pi-sign-out mr-2"></i>
                     <span class="font-medium">Logout</span>
                  </a>
               </li>
            </ul>
         </li>
         <li v-if="userProfile?.isAdmin">
            <a
               v-ripple
               @click="$emit('businessGoalEmailClicked')"
               class="flex p-3 lg:px-3 lg:py-2 align-items-center text-600 hover:text-900 hover:surface-200 font-medium border-round cursor-pointer
                        transition-duration-150 transition-colors p-ripple"
            >
               <i class="pi pi-inbox text-base lg:text-2xl mr-2 lg:mr-0"></i>
               <span class="block lg:hidden font-medium">Business goal emails</span>
            </a>
         </li>
         <li v-if="userProfile?.isAdmin">
            <a
               v-ripple
               class="flex p-3 lg:px-3 lg:py-2 align-items-center text-600 hover:text-900 hover:surface-200 font-medium border-round cursor-pointer
                        transition-duration-150 transition-colors p-ripple"
            >
               <i
                  class="pi pi-bell text-base lg:text-2xl mr-2 lg:mr-0"
                  v-badge.danger
               ></i>
               <span class="block lg:hidden font-medium">Notifications</span>
            </a>
         </li>
         <li v-if="userProfile?.isAdmin">
            <a
               v-ripple
               @click="$emit('customerEditClicked')"
               class="flex p-3 lg:px-3 lg:py-2 align-items-center text-600 hover:text-900 hover:surface-200 font-medium border-round cursor-pointer
                        transition-duration-150 transition-colors p-ripple"
            >
               <i class="pi pi-cog text-base lg:text-2xl mr-2 lg:mr-0"></i>
               <span class="block lg:hidden font-medium">Customer Settings</span>
            </a>
         </li>
      </ul>
      <CustomerSelector v-if="userProfile?.isAdmin"/>
   </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

import CustomerSelector from '@/components/nav/CustomerSelector.vue'

import { useUserProfileQuery } from '@/composables/queries/useUserProfileQuery'


export default defineComponent({
   name: 'TopBar',

   components: {
      CustomerSelector
   },

   setup() {
      const { data:userProfile } = useUserProfileQuery()

      return {
         userProfile
      }
   }
})
</script>

<style lang="scss">
.profile-dropdown {
   color: #495057;
}

.logout{
   &:hover {
      color: #4338CA;
  background: #EEF2FF;
   }
}
</style>
