import { QueryClient, useQuery } from '@tanstack/vue-query'
import QueryKeys from '@/vue-query/QueryKeys'
import { FeatureFlagsConfiguration } from '@/api/contracts/models'
import { useFeatureFlagsApi } from '../api/useFeatureFlagsApi'

export function useFeatureFlagsQuery() {
   const client = useFeatureFlagsApi()
   return useQuery([QueryKeys.FeatureFlags], () => client.get(), {
      refetchInterval: false
   })
}

export function useFeatureFlagsQueryPrefetch(
   queryClient: QueryClient
): Promise<void> {
   const client = useFeatureFlagsApi()

   return queryClient.prefetchQuery<FeatureFlagsConfiguration>([QueryKeys.FeatureFlags], () =>
      client.get()
   )
}
