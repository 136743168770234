<template>
   <div v-if="menuItems" class="c-customer-selector">
      <Dropdown
         :scrollHeight="'300px'"
         v-model="model"
         :options="menuItems"
         :option-value="option => option.id"
         @update:model-value="updateContext"
         optionLabel="name"
         placeholder="Select a customer"
         :filter="true" filterPlaceholder="Find customer"/>
   </div>
</template>

<script lang="ts">
import { defineComponent, computed, watch, ref } from 'vue'
import { useCustomersQuery } from '@/composables/queries/useCustomersQuery'
import { useContextUpdate } from '@/composables/mutations/useContextUpdate'
import Dropdown from 'primevue/dropdown'
import { MenuItem } from '@/types/types'
import { useQueryClient } from '@tanstack/vue-query'
import { useRouter } from 'vue-router'
import { UpdateContextRequest } from '@/api/contracts/models'
import { useParams } from '@/composables/utils/useParams'
import { useUserProfileQuery } from '~/composables/queries/useUserProfileQuery'
import { useUserProjectDisplaySettingsQuery } from '~/composables/queries/useUserProjectDisplaySettingsQuery'

export default defineComponent({
   name: 'ContextSelector',
   components: {
      Dropdown
   },
   setup(props, context) {
      const { customerId } = useParams()

      const model = ref(customerId.value as string)

      watch(
         () => customerId,
         () => (model.value = customerId.value as string),
         { immediate: true, deep: true }
      )

      const router = useRouter()

      const goToDetails = id => {
         router.push({
            name: 'customerDetails',
            params: {
               customerId: id
            }
         })
      }
      const { data: customers } = useCustomersQuery()

      const { data: settings } = useUserProjectDisplaySettingsQuery()
      const anonymizeText = (text) => {
            return useAnonymizedText(text, settings)
      }

      const menuItems = computed(() => {
         const values = customers.value
            ? _orderBy(customers.value!.map(m => {
                 return {
                    name: anonymizeText(m.displayName).value,
                    id: m.id,
                    icon: ''
                 }
              }), x => x.name)
            : null

         return values
      })

      const updateContextMutation = useContextUpdate(useQueryClient())

      const { data: userProfile } = useUserProfileQuery()

      const updateContext = newValue => {
         const customer = customers.value!.find(c => c.id == newValue)

         const updateContextRequest = {
            ...userProfile.value?.context,
            contextCustomerId: newValue,
            contextProjectId: customer!.defaultProjectId,
         } as UpdateContextRequest

         updateContextMutation.mutate(updateContextRequest)

         let newRouteName = router.currentRoute.value.name
         if (router.currentRoute.value.fullPath.endsWith('wall-dashboard')) {
            newRouteName = 'wall-dashboards'
         }
         
         if (router.currentRoute.value.fullPath.endsWith('admin')) {
            newRouteName = 'dashboard'
         }

         if (router.currentRoute.value.fullPath.indexOf('repository-info-details') != -1) {
            newRouteName = 'deployments'
         }

         if (router.currentRoute.value.fullPath.indexOf('deployment-details')!= -1) {
            newRouteName = 'deployments'
         }

         router.push({
            name: newRouteName!,
            params: {
               customerId: newValue,
               projectId: customer!.defaultProjectId
            }
         })
      }

      return {
         model,
         updateContext,
         menuItems
      }
   }
})
</script>
