import { ref, watch } from 'vue'
import { useProjectsQuery } from './queries/useProjectsQuery'
import { useCustomersQuery } from './queries/useCustomersQuery'
import { useParams } from '@/composables/utils/useParams'
import { useUserProfileQuery } from './queries/useUserProfileQuery'
import { ProjectDto } from '~/api/contracts/models'

export function useContextProjects() {
   const { customerId } = useParams()

   const { data: userProfile } = useUserProfileQuery()
   const { data: projects } = useProjectsQuery(customerId)
   const { data: customers } = useCustomersQuery()

   const result = ref<undefined | ProjectDto[]>(undefined)
   const setContextData = () => {

      if (userProfile.value?.isAdmin)
      {
         result.value = projects.value!.filter(
            p => p.customerId == customerId.value
         )
      }
      else 
      {
         result.value = projects.value
      }
   }

   watch(
      [customerId, projects, customers, userProfile],
      () => {
         if (
            customerId.value &&
            projects.value &&
            customers.value
         ) {
            setContextData()
         }
      },
      { immediate: true, deep: true }
   )

   return result
}
